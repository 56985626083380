import axios from 'axios';
import { initAPIMock, initAPIPublicMock } from './mocks';
import { toast } from 'react-toastify';
import { ERRORS } from '../utils/errors';

export let api = axios.create({
  baseURL: 'https://app.piminder.com/monolit',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

export const ignoredRoute = (pathname, errorCode) => {
  const ignoredRoutes = [
    {
      route: '/loja',
      errors: ['E-018']
    },
    { route: '/configuracoes', errors: ['E-018'] }
  ];
  const route = ignoredRoutes.find((route) => pathname.includes(route.route));
  if (route) {
    if (route.errors.includes(errorCode)) {
      return true;
    }
  }
  return false;
};

export const authentication = axios.create({
  baseURL: 'https://app.piminder.com/auth/v1/api/auth',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

export const apiPublic = axios.create({
  baseURL: 'https://app.piminder.com/monolit',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const authenticationPublic = axios.create({
  baseURL: 'https://app.piminder.com/auth/v2/api/auth',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const authenticationPublicV1 = axios.create({
  baseURL: 'https://app.piminder.com/auth/v1/api/auth',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const invoices = axios.create({
  baseURL: 'https://app.piminder.com/invoice/v1/api/inv',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

export const notifications = axios.create({
  baseURL: 'https://app.piminder.com/notification/v1/api/notification',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

export const products = axios.create({
  baseURL: 'https://app.piminder.com/product/v1/api/product',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

export const wallets = axios.create({
  baseURL: 'https://app.piminder.com/credit/v1/api/credit',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json'
  }
});

const errorInterceptor = (error) => {
  if (error?.response && error?.response?.status === 401) {
    toast.error('Sua sessão expirou, faça login novamente');
    localStorage.clear();
    window.location.href = '/';
  }
  if (error?.response && error?.response?.status >= 400) {
    if (!ignoredRoute(window.location.href, error?.response?.data?.message)) {
      toast.error(ERRORS[error?.response?.data?.message]);
    }
  }
  return Promise.reject(error);
};

api.interceptors.response.use((response) => response, errorInterceptor);
if (process.env.REACT_APP_API_ENV === 'mock') {
  initAPIPublicMock(apiPublic);
  initAPIMock(api);
}

authentication.interceptors.response.use((response) => response, errorInterceptor);
invoices.interceptors.response.use((response) => response, errorInterceptor);
apiPublic.interceptors.response.use((response) => response, errorInterceptor);
authenticationPublic.interceptors.response.use((response) => response, errorInterceptor);
authenticationPublicV1.interceptors.response.use((response) => response, errorInterceptor);
products.interceptors.response.use((response) => response, errorInterceptor);
notifications.interceptors.response.use((response) => response, errorInterceptor);
